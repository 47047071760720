<template>
  <div class="main-body">
    <div>
      <nav-bar></nav-bar>
    </div>
    <div class="main-container">
      <div class="register-container">
        <!-- 注册标题 -->
        <div>
          <h3>注册{{siteInfo.info ? siteInfo.info.sitename : ''}}账号</h3>
        </div>
        <!-- 登录区域 -->
        <div class="username-login">
          <div class="input mobile">
            <input type="text" placeholder="手机号码" v-model="phone">
          </div>
          <div class="check-code-container">
            <div class="input check-code">
              <input type="text" placeholder="短信验证码" v-model="sms">
            </div>
            <span class="ml-1">
                <el-button :type="$config.buttonType" @click="getCheckCode">发送验证码</el-button>
            </span>
          </div>
          <div class="input password">
            <input type="password" placeholder="密码" v-model="password">
          </div>
          <div class="cannot-register">
            <span>
              已有账号？
              <a href="/login" class="login-immediately">立即登录</a>
            </span>
          </div>
          <div class="login-button">
            <el-button :type="$config.buttonType" class="button" size="medium" @click="toRegister">注册</el-button>  
          </div> 
        </div>
        <!-- 登录协议 -->
        <div class="protocol-container">
          <el-switch v-model="agreement" active-color="#13ce66" inactive-color="#aaaaaa"></el-switch>
          已阅读并同意
          <a href="/protocol/1">《用户协议》</a>
          <a href="/protocol/2">《隐私协议》</a>
        </div>
      </div>
    </div>
    <div>
      <web-footer></web-footer>
    </div>
  </div>
</template>

<script type="text/javascript">
import NavBar from '@/views/user/components/NavBar'
import WebFooter from '@/views/user/components/WebFooter'
import {getLocalStorage,setLocalStorage,getSessionStorage,removeLocalStorage} from '@/utils/auth'
import {getSiteInfo,userLogin,getUserInfo,getSmsCheckCode,userRegister} from '@/api/user'

export default {
  data() {
    return {
      siteInfo:'',
      password:'',
      phone:'',
      sms:'',
      agreement:false,
      checkCode:''
    }
  },
  components: {
    NavBar,
    WebFooter
  },
  props:{

  },
  created(){
    //清除即存信息
    removeLocalStorage("token")
    removeLocalStorage("userInfo")
    this.findSiteInfo()
  },
  methods:{
    findSiteInfo(){
        this.siteInfo = getLocalStorage('siteInfo')
    },
    toRegister(){
      //验证手机号码格式
      if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))){ 
        this.$notify.error({
            title:'错误',
            message:'手机号' + this.phone + '格式有误！'
        })
        return; 
      }
      //验证码是否正确
      if(this.sms.trim() == '' || this.sms != this.checkCode){
        this.$notify.error({
            title:'错误',
            message:'短信验证码不正确！'
        })
        return;
      }
      if(this.password.trim() == ''){
        this.$notify.error({
            title:'错误',
            message:'密码不能为空！'
        })
        return;
      }
      //去注册，注册成功后，自动登录
      if(!this.agreement){
        this.$notify.warning({
            title:'警告',
            message:'注册用户之前请同意相关协议！'
        })
        return; 
      }
      //以上都没有问题就注册
      userRegister({
        phone:this.phone,
        password:this.password
      }).then(
        res=>{
          if(res.code == 1){
            //注册成功之后，重新登录一下
            this.$notify.success({
                title:'成功',
                message:'注册成功，将返回之前的页面！'
            })
            userLogin({
              phone:this.phone,
              password:this.password
            }).then(
              res=>{
                if(res.code == 1){
                  setLocalStorage('token',res.data)  
                }
                //回到上一个页面
                this.$router.push(getSessionStorage('previousRoute') || '/')
              }
            )
            
          }else{
            this.$notify.error({
                title:'错误',
                message:'注册失败，请稍后重试！'
            })
          }
        }
      )
    },
    getCheckCode(){
      if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.phone))){ 
        this.$notify.error({
            title:'错误',
            message:'手机号' + this.phone + '格式有误！'
        })
        return; 
      }
      //获取验证码
      getSmsCheckCode({
        phone:this.phone
      }).then(
        res=>{
          if(res.code == 1){
            this.checkCode = res.data
            this.$notify.success({
                title:'成功',
                message:'验证码已发送！'
            })
          }else{
            this.$notify.error({
                title:'错误',
                message:'手机号' + res.data.phone + '已经注册！'
            })
          }
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.main-body{
  background-image:url('../../assets/images/login-cover-5.jpg');
  background-position:center top;
  background-repeat: no-repeat;
  display:flex;
  flex-direction: column;
  .main-container{
    position: relative;
    width:1200px;
    margin:0px auto;
    min-height:570px;
    height:700px;
    max-height:760px;
    .register-container{
      position: absolute;
      height:400px;
      width:400px;
      right:0px;
      top:50px;
      background-color:white;
      h3{
        text-align: center;
        font-size:18px;
        font-weight:bold;
        height:60px;
        line-height: 60px;
      }
      .input{
        display:block;
        height:20px;
        width:315px;
        padding:8px;
        outline:medium;
        color:$text-color;
        border:solid 1px #aaa;
        border-radius: 0px;
        margin-left:20px;
        margin-top:30px;
        padding-left:35px;
        input{
          font-size:16px;
          height:20px;
          width:260px;
          color:#333;
        }
      }
      .username{
        background-image:url('../../assets/images/login-icon-user.png');
        background-repeat: no-repeat;
        background-position: 5px center;
      }
      .password{
        background-image:url('../../assets/images/login-icon-lock.png');
        background-repeat: no-repeat;
        background-position: 5px center;
      }
      .mobile{
        background-image:url('../../assets/images/login-icon-mobile.png');
        background-repeat: no-repeat;
        background-position: 5px center;
      }
      .check-code-container{
        margin-top:30px;
        display:flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        .check-code{
          margin-top:0px;
          background-image:url('../../assets/images/login-icon-checkcode.png');
          background-repeat: no-repeat;
          background-position: 5px center;
          width:185px;
          input{
            width:170px;
          }
        }
      }
      .cannot-register{
        width:320px;
        margin:10px auto;
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        .login-immediately{
          color:$theme-color;
        }
      }
      .login-button{
        width:360px;
        margin:10px auto;
        height:30px;
        .button{
          width:100%;
        }
      }
      .protocol-container{
        width:350px;
        margin:20px auto;
        line-height:20px;
        a{
          color:$theme-color;
          &:hover{
            color:#333;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.login-tabs{
  width:360px;
  margin:0px auto;
  .el-tabs__item{
    width:180px;
    text-align: center;
    font-size:16px;
    font-weight:bold;
  }
  .el-tabs__item:hover{
    color:$theme-color;
  }
  .el-tabs__item.is-active{
    color:$theme-color;
  }
  .el-tabs__active-bar{
    background-color:$theme-color;
  }
}
</style>